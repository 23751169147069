<template>
  <div class="checkbox-base">
    <label class="form-control">
      <input type="checkbox" name="checkbox" :v-model="check" :id="id" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'checkbox-base',
  props: {
    check: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    cursor: pointer;
    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
    border: 0.15em solid $team_checkbox;
    border-radius: 0.25em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  input[type="checkbox"]::before {
    content: "";
    width: 0.48em;
    height: 0.48em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: $team_blue;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
</style>
