<template>
  <div class="add-admin">
    <popup-base>
      <div class="begin bg-white w-3/4 m-auto border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('newAccess') }}</div>
            <div class="sous-title mt-3">{{ $t('toAdministration') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="form py-6">
            <div class="login-form">
              <div class="label mb-2">{{ $t('name') }}</div>
              <div class="input-bloc">
                <input
                  v-model="name"
                  type="text"
                  class="ipt w-full p-4 border rounded-lg"
                >
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('surname') }}</div>
              <div class="input-bloc">
                <input
                  v-model="surname"
                  type="text"
                  class="ipt w-full p-4 border rounded-lg"
                >
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('phone') }}</div>
              <div class="input-bloc">
                <input
                  v-model="phone"
                  type="text"
                  class="ipt w-full p-4 border rounded-lg"
                  @keypress="isNumberOnly"
                >
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('email') }}</div>
              <div class="input-bloc">
                <input
                  v-model="email"
                  type="email"
                  class="ipt w-full p-4 border rounded-lg"
                >
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('role') }}</div>
              <div class="input-bloc">
                <Multiselect
                  v-model="role"
                  :options="optionsRole"
                  :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg p-4 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                />
              </div>
            </div><br>
            <div
              v-if="error"
              class="text-red-500 text-center mt-2"
            >
              {{ $t('errorAddAdmin') }}
            </div>
          </div>
          <div class="login-button flex items-center justify-end mb-8">
            <div class="cancel-button mr-3">
              <button-base
                :label="$t('cancelButton')"
                fontcolor="#909090"
                background="#FFFFFF"
                @click="closeAdd"
              />
            </div>
            <div class="valid-button ml-3">
              <button-base
                :label="$t('addButton')"
                :is-loading="loader"
                @click="createAdmin"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import close from '../../../assets/icons/ic_close.svg'
import ButtonBase from '../add/button-base'
import Multiselect from '@vueform/multiselect'
import http from '../../../plugins/https'
export default {
  name: 'add-admin',
  components: { Multiselect, ButtonBase, PopupBase },
  data () {
    return {
      icons: { close },
      name: '',
      surname: '',
      phone: '',
      email: '',
      role: null,
      optionsRole: [
        this.$t('administratorsMenu'),
        this.$t('zoneChief'),
        this.$t('contactPoint')
      ],
      error: false,
      loader: false
    }
  },
  methods: {
    createAdmin () {
      if (this.name === '' || this.surname === '' || this.phone === '' || this.email === '' || this.role === null) {
        this.error = true
      } else {
        this.error = false
        this.loader = true
        const params = new URLSearchParams()
        params.append('email', this.email)
        params.append('name', this.name)
        params.append('surname', this.surname)
        params.append('phone', this.phone)
        params.append('role', this.role)
        params.append('creator', this.$store.getters.userId)
        http.post('https://us-central1-teamsecu-f002b.cloudfunctions.net/exampleFunction', params)
          .then(response => {
            console.log(response)
            this.$emit('openSuccess', true)
          }).catch(error => {
            this.loader = false
            console.log(error)
          })
      }
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    closeAdd () {
      this.$emit('closeAddAdmin', false)
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .sous-title {
    color: #909090;
  }
  .header, .ipt {
    background: $team_gray_popup;
  }
  .cancel-button::v-deep {
    .button-base {
      .page-button-real {
        border: none;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .valid-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
