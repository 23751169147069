<template>
  <div class="administrators">
    <div class="begin pb-12">
      <div class="header">
        <!-- HEADER POPUP -->
        <the-header
          :show-file-button="false"
          :show-number="false"
          :add-button="$t('addAccessButton')"
          @searchForItems="searchForItems"
          @clickBlueButton="clickBlueButton"
        />
        <!-- HEADER POPUP END -->
      </div>
      <div class="body">
        <div class="header-table flex items-center p-4 bg-white rounded-xl border font-medium text-lg">
          <div class="action w-1/12">
            <checkbox-base :check="allCheck" @click="allCheckChange"/>
          </div>
          <div class="action w-1/2 mr-4">{{ $t('completeName') }}</div>
          <div class="action w-1/4 mr-4">{{ $t('contact') }}</div>
          <div class="action w-1/3 mr-4">{{ $t('email') }}</div>
          <div class="action w-1/4">{{ $t('role') }}</div>
        </div>
        <div class="header-body">
          <div class="loading" :class="{ 'not_empty': loading === false }">
            <loading
              :active="loading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              background-color="#FFFFFF"
              color="#324971"
              loader="dots"
            ></loading>
          </div>
          <div
            v-if="nothing"
            class="no-student text-center text-xl text-gray-500 py-32"
          >
            {{ $t('noAdminAdded') }}
          </div>
          <admin-lists
            v-for="(item, index) in sortedArrayAdmin"
            :key="index"
            :admin-lists="item"
            :is-check="isAllChecked"
            @isJustChecked="isJustChecked"
            @isJustNotChecked="isJustNotChecked"
          />
        </div>
      </div>
    </div>
    <!-- ADD ADMIN POPUP -->
    <add-admin
      v-if="isAddAdmin"
      @closeAddAdmin="closeAddAdmin"
      @openSuccess="openSuccess"
    />
    <!-- SUCCESS POPUP BASE -->
    <success-popup-base
      v-if="isSuccessPopup"
      :success-description="$t('adminAddSuccess')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import CheckboxBase from '../../components/helper/add/checkbox-base'
import AdminLists from '../../components/helper/administrators/admin-lists'
import AddAdmin from '../../components/helper/administrators/add-admin'
import SuccessPopupBase from '../../components/helper/add/success-popup-base'
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore'
import { firestore } from '../../main'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
export default {
  name: 'index',
  components: { Loading, SuccessPopupBase, AddAdmin, AdminLists, CheckboxBase, TheHeader },
  data () {
    return {
      allAdmin: [],
      isAllChecked: true,
      selectedInMatch: [],
      isNotSelected: [],
      allCheck: true,
      isAddAdmin: false,
      isSuccessPopup: false,
      loading: true,
      fullPage: false,
      nothing: false,
      searching: ''
    }
  },
  created () {
    this.getAllAdmins()
  },
  computed: {
    sortedArrayAdmin: function () {
      let sorted = this.allAdmin
      if (this.searching !== '') {
        let authorNameSearchString = this.searching
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.name !== null && (item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() + ' ' + item.surname.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.email !== null && (item.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    }
  },
  methods: {
    async getAllAdmins () { // METHOD TO GET ALL ADMINS
      const querySnapshot = await getDocs(query(collection(firestore, 'admins'),
        orderBy('uid', 'desc'),
        orderBy('createdAt', 'desc'),
        where('uid', '!=', 'nILgaNirsifsZrtn3jD56wfYbuF2')
      ))
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAdmin.push(doc.data())
      })
      if (this.allAdmin.length === 0) {
        this.loading = false
        this.nothing = true
      } else {
        this.loading = false
        this.nothing = false
      }
    },
    searchForItems (val) { // GET INPUT SEARCH
      this.searching = val
      console.log(val)
    },
    openSuccess (val) { // OPEN SUCCESS POPUP FOR SUCCESS ADD ADMIN
      this.closeAddAdmin(false)
      this.isSuccessPopup = val
    },
    closeSuccess (val) { // CLOSE SUCCESS POPUP
      this.isSuccessPopup = val
      window.location.reload()
    },
    closeAddAdmin (val) { // CLOSE ADD ADMIN POPUP
      this.isAddAdmin = val
    },
    clickBlueButton () { // OPEN ADD ADMIN POPUP
      this.isAddAdmin = true
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
    allCheckChange () {
      this.isAllChecked = this.allCheck
    },
    isJustChecked (answer) {
      this.allCheck = false
      this.selectedInMatch.push(answer)
    },
    isJustNotChecked (answer) {
      for (let i = 0; i < this.selectedInMatch.length; i++) {
        if (this.selectedInMatch[i] === answer) {
          this.selectedInMatch.splice(i, 1)
        }
      }
      this.allCheck = false
      this.isNotSelected.push(answer)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .action {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
