<template>
  <div class="success-popup-base">
    <popup-base>
      <div class="begin bg-white m-auto w-3/4 rounded-lg border p-8">
        <div class="header flex items-center w-full">
          <div class="sous-header flex flex-col justify-center w-full px-20">
            <div class="icon m-auto"><icon :data="icons.success" original width="75" height="75"/></div>
            <div class="title mt-8 font-bold text-3xl text-center">{{ successTitle }}</div>
            <div class="description mt-8 text-center leading-relaxed">{{ successDescription }}</div>
            <div class="ok-button mt-8">
              <button-base
                :label="$t('okButton')"
                @click="closeSuccess"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
import ButtonBase from './button-base'
import success from '../../../assets/icons/ic_incident_success.svg'
export default {
  name: 'success-popup-base',
  components: { ButtonBase, PopupBase },
  props: {
    successTitle: {
      type: String,
      default: 'Succès'
    },
    successDescription: {
      type: String,
      default: 'Succès'
    }
  },
  data () {
    return {
      icons: {
        success
      }
    }
  },
  methods: {
    closeSuccess () {
      this.$emit('closeSuccess', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .title {
    color: $team_blue;
  }
  .ok-button::v-deep {
    .button-base {
      text-align: center;
      text-align: -moz-center;
      text-align: -webkit-center;
      .page-button-real {
        border-radius: .25rem;
        width: 50%;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
