<template>
  <div class="admin-lists">
    <div class="begin mt-1">
      <div class="header-table flex items-center p-4 bg-white text-lg rounded-xl shadow border">
        <div class="action w-1/12">
          <checkbox-base :check="isCheck" :id="position" @click.stop="clickOnCheckbox" />
        </div>
        <div class="action w-1/2 mr-4 flex items-center">
          <div
            :style="backgroundDefine"
            class="picture w-12 h-12 text-center pt-4 mr-2 rounded-full text-sm font-bold text-white"
          >
            {{ getFirstLetter(adminLists.surname + ' ' + adminLists.name) }}
          </div>
          <div class="name ml-4">{{ adminLists.surname + ' ' + adminLists.name }}</div>
        </div>
        <div class="action w-1/4 mr-4">{{ adminLists.phone }}</div>
        <div class="action w-1/3 mr-4">{{ truncateString(adminLists.email, 20) }}</div>
        <div class="action w-1/4">{{ adminLists.right }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxBase from '../add/checkbox-base'
export default {
  name: 'admin-lists',
  components: { CheckboxBase },
  props: {
    adminLists: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    position: {
      type: Number,
      default: 0
    },
    isCheck: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    clickOnCheckbox () {
      if (this.isCheck) {
        this.$emit('isJustChecked', this.position)
      } else {
        this.$emit('isJustNotChecked', this.position)
      }
      console.log(this.position)
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .header-table:hover {
    border-left: 2.5px solid $team_blue;
  }
</style>
